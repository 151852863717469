<template>
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="收件人">
      <el-input style="width: 250px" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="消息类型">
      <el-select v-model="form.region" placeholder="请选择消息类型">
        <el-option label="个人消息" value="Pri"></el-option>
        <el-option label="系统通知" value="Sys"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="主题">
      <el-input style="width: 250px" v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item label="消息内容">
      <el-input type="textarea" v-model="form.desc"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">立即发送</el-button>
      <el-button>取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "writeMessage1",
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      }
    }
  },
  methods: {
    onSubmit() {
      console.log('submit!');
    }
  }
}
</script>

<style scoped>

</style>
